import React from 'react';
import { Helmet } from 'react-helmet';

import SectionHeader from '../../Components/SectionHeader/SectionHeader';

import FAQsData from '../../Data/FAQs.json';

import './FAQs.scss';

const FAQs = () => {
	return (
		<>
			<Helmet>
				<title>Frequently Asked Questions — PRISSM</title>
			</Helmet>

			<SectionHeader
				title='Frequently Asked Questions'
				subtitle='Need help with something? Here are our most frequently asked questions.'
			/>

			<ul className='faqs'>
				{FAQsData.map(FAQs => (
					<li key={FAQs.FAQsKey}>
						<h3 className='faqs__question'>{FAQs.FAQsQuestion}</h3>

						<p className='faqs__answer'>{FAQs.FAQsAnswer}</p>
					</li>
				))}
			</ul>
		</>
	);
};

export default FAQs;
