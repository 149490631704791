import React from 'react';
import { Helmet } from 'react-helmet';

import SectionHeader from '../../Components/SectionHeader/SectionHeader';

import TEOne from '../../Images/te-img-one.webp';
import TETwo from '../../Images/te-img-two.webp';
import TEThree from '../../Images/te-img-three.webp';

import './TechnicalExperts.scss';

const TechnicalExperts = () => {
	return (
		<>
			<Helmet>
				<title>Technical Experts — PRISSM</title>
			</Helmet>

			<SectionHeader
				title='TECHNICAL EXPERTS'
				subtitle='We can assist you in discovering skilled professionals who can enhance your business operations by introducing streamlined workflows, automation, and remote staff to revolutionize the way you work.'
			/>

			<ul className='technical__expert__images'>
				<li>
					<img src={TEOne} alt='' />
				</li>

				<li>
					<img src={TETwo} alt='' />
				</li>

				<li>
					<img src={TEThree} alt='' />
				</li>
			</ul>
		</>
	);
};

export default TechnicalExperts;
