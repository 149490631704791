import React from 'react';
import { Helmet } from 'react-helmet';
import { PopupButton } from 'react-calendly';

import './Home.scss';

import PamFounder from '../../Images/pam-founder-prissm.webp';
import { Link } from 'react-router-dom';

const Home = () => {
	return (
		<>
			<Helmet>
				<title>PRISSM: Bridges the Gap between Talent and Business</title>

				<meta
					name='description'
					content='Outsource with confidence - PRISSM delivers reliable, customized solutions to drive your business forward. Contact us for top-notch staffing solutions.'
				/>
			</Helmet>

			<section className='home'>
				<div className='home__overlay'></div>

				<div className='home__content'>
					<p>Staffing Solutions Company</p>

					<h1>
						Bridges the Gap between <br /> Talent and Business
					</h1>

					<p>
						If you're ever dissatisfied with your Virtual Assistant, we're
						happy to find you a replacement at no extra charge.
						Additionally, we'll provide you with a complimentary week of
						VA service to assist you in adjusting to your new assistant.
					</p>

					<ul>
						<li>
							<PopupButton
								url='https://calendly.com/pam-prissm/30min'
								rootElement={document.getElementById('root')}
								text='Book Discovery Call'
							/>
						</li>

						<li>
							<Link to='/frequently-asked-questions'>
								Read FAQs — Answer your questions easily!
							</Link>
						</li>
					</ul>
				</div>
			</section>

			<section id='about' className='about'>
				<div className='about__content'>
					<h2>Let us know what you need and we'll do the rest.</h2>

					<p>
						At PRISSM Staffing Solutions, we offer job placement services
						to both job seekers and employers. Our founder, Pam, has over
						a decade of experience in customer service, outsourcing, and
						virtual assistance, and we provide offshore staffing services
						to businesses. Our aim is to create job opportunities and
						fight poverty in developing countries like the Philippines. We
						have many different options available to help job seekers find
						the right job for them. Don't go through the job search alone,
						let us help you transform your business!
					</p>
				</div>
			</section>

			<section className='founder'>
				<div className='founder__container'>
					<div className='founder__details'>
						<h2>Meet Pam, Founder of PRISSM</h2>

						<p>
							Pam commenced her career in 2009 as a call center agent in
							Cebu, Philippines. Subsequently, she transitioned to the
							role of a recruitment outsourcing specialist. Being driven
							by her desire to aid the less privileged Filipino populace,
							she aspired to establish her own outsourcing enterprise. In
							2018, Pam relinquished her job and embraced the profession
							of a freelancer. She collaborated with multiple clients and
							gained new insights. Pam realized that working as a virtual
							assistant could benefit people financially and make a
							significant difference, particularly in a developing nation
							such as the Philippines. With her aspiration of creating
							her own outsourcing company still etched in her mind, and
							having acquired almost a decade of experience, she founded
							PRISSM in 2023. Pam believes that businesses should strive
							to have a purpose beyond mere financial gains.
						</p>
					</div>

					<div className='founder__profile'>
						<img
							src={PamFounder}
							alt='Pam Illustrisimo - CEO & Founder, PRISSM'
						/>
					</div>
				</div>
			</section>

			<ul className='mv'>
				<li className='mission'>
					<h3>MISSION</h3>

					<p>
						Our mission is to deliver the best value to our clients by
						providing highly qualified and fitting candidates at an
						affordable cost. Our dedication to being a trustworthy partner
						in your hiring journey is something we take pride in. We offer
						comprehensive staffing and recruitment services to a wide
						range of industries, from Healthcare to Technology,
					</p>
				</li>

				<li className='vision'>
					<h3>VISION</h3>

					<p>
						We strive to transcend the traditional role of a staffing
						company. Our ultimate goal is to serve as a conduit for both
						talented individuals seeking fulfilling career opportunities
						and businesses in need of skilled professionals. Our approach
						is not limited to any one industry, as we are committed to
						providing a diverse range of talent solutions across various
						sectors.
					</p>
				</li>
			</ul>
		</>
	);
};

export default Home;
