import React from 'react';

import './SectionHeader.scss';

const PageTitle = ({ title, subtitle }) => {
	return (
		<section className='section__header'>
			<h1 className='section__header__title'>{title}</h1>

			<p className='section__header__subtitle'>{subtitle}</p>
		</section>
	);
};

export default PageTitle;
