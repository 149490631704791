import React from 'react';
import { PopupButton } from 'react-calendly';
import { Link } from 'react-router-dom';

import './Footer.scss';

const Footer = () => {
	return (
		<footer>
			<div className='footer__content'>
				<ul>
					<li>
						<Link to='/'>Home</Link>
					</li>

					<li>
						<Link to='/technical-experts'>Technical Experts</Link>
					</li>

					<li>
						<Link to='/virtual-assistants'>Virtual Assistants</Link>
					</li>

					<li>
						<Link to='/frequently-asked-questions'>FAQs</Link>
					</li>

					<li>
						<PopupButton
							url='https://calendly.com/pam-prissm/30min'
							rootElement={document.getElementById('root')}
							text='Book Discovery Call'
						/>
					</li>
				</ul>

				<p className='copyright'>2023 PRISSM &copy; All Rights Reserved.</p>
			</div>
		</footer>
	);
};

export default Footer;
