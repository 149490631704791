import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PopupButton } from 'react-calendly';

import './Navigation.scss';

import PrissmLogo from '../../Icons/prissm-logo.webp';

import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const Navigation = () => {
	const [drop, setDrop] = useState(false);
	const [dp, setDp] = useState(false);

	const showMenu = () => {
		setDrop(true);
		setDp(true);
	};

	const closeMenu = () => {
		setDrop(false);
		setDp(false);
	};

	return (
		<header>
			<nav>
				<div>
					<Link to='/' onClick={closeMenu}>
						<img src={PrissmLogo} alt='PRISSM Logo' />
					</Link>
				</div>

				<ul
					className={drop ? 'nav__links nav__links--active' : 'nav__links'}
				>
					<li onClick={closeMenu}>
						<Link to='/technical-experts'>Technical Experts</Link>
					</li>

					<li onClick={closeMenu}>
						<Link to='/virtual-assistants'>Virtual Assistants</Link>
					</li>

					<li onClick={closeMenu}>
						<Link to='/frequently-asked-questions'>FAQs</Link>
					</li>

					<li onClick={closeMenu}>
						<PopupButton
							url='https://calendly.com/pam-prissm/30min'
							rootElement={document.getElementById('root')}
							text='Book Discovery Call'
						/>
					</li>
				</ul>

				<div className='mobile__menu'>
					<AiOutlineMenu
						className={dp ? 'open menu__none' : 'open'}
						onClick={showMenu}
					/>

					<AiOutlineClose
						className={dp ? 'close' : 'close menu__none'}
						onClick={closeMenu}
					/>
				</div>
			</nav>
		</header>
	);
};

export default Navigation;
