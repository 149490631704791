import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Components
import Navigation from './Components/Navigation/Navigation';
import Footer from './Components/Footer/Footer';

// Pages
import Home from './Pages/Home/Home';
import VirtualAssistants from './Pages/VirtualAssistants/VirtualAssistants';
import TechnicalExperts from './Pages/TechnicalExperts/TechnicalExperts';
import FAQs from './Pages/FAQs/FAQs';

const App = () => {
	return (
		<>
			<BrowserRouter>
				<Navigation />

				<Routes>
					<Route path='/' element={<Home />} />

					<Route
						path='/virtual-assistants'
						element={<VirtualAssistants />}
					/>

					<Route
						path='/technical-experts'
						element={<TechnicalExperts />}
					/>

					<Route path='/frequently-asked-questions' element={<FAQs />} />
				</Routes>

				<Footer />
			</BrowserRouter>
		</>
	);
};

export default App;
