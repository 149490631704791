import React from 'react';
import { Helmet } from 'react-helmet';

import './VirtualAssistants.scss';

import SectionHeader from '../../Components/SectionHeader/SectionHeader';

import FAQsVAData from '../../Data/VirtualAssistantFAQs.json';

import VAOne from '../../Images/va-img-one.webp';
import VATwo from '../../Images/va-img-two.webp';
import VAThree from '../../Images/va-image-three.webp';

const VirtualAssistants = () => {
	return (
		<>
			<Helmet>
				<title>Virtual Assistants — PRISSM</title>
			</Helmet>

			<SectionHeader
				title='VIRTUAL ASSISTANTS'
				subtitle='Rest assured, we are committed to assisting you in your search for a skilled Filipino Virtual Assistant who specializes in Medical, Customer Service, Admin, Marketing, or other areas. Our process involves finding and preparing the candidates, as well as fully qualifying them to ensure that the individual you hire is the best fit for your needs.'
			/>

			<ul className='virtual__assistants__images'>
				<li>
					<img src={VAOne} alt='' />
				</li>

				<li>
					<img src={VATwo} alt='' />
				</li>

				<li>
					<img src={VAThree} alt='' />
				</li>
			</ul>

			<SectionHeader title='Frequently Asked Questions - VA' />

			<ul className='faqs__va'>
				<li>
					<h3 className='faqs__va__question'>
						Where are the Virtual Assistants based?
					</h3>

					<p className='faqs__va__answer'>
						Most of our Virtual Employees are from the Philippines. Why
						hire Filipino VAs?
					</p>

					<ul>
						<li>
							Filipinos are known for their high-quality work as well as
							having an excellent reputation in the industry.
						</li>

						<li>
							Filipinos Have a Positive Attitude and Work Ethic that is
							Unparalleled
						</li>

						<li>
							Filipino VAs are Easier to Train than Other Nationalities,
							Making them More Cost-Effective
						</li>

						<li>
							Filipinos are highly skilled and proficient English
							speaking people.
						</li>
					</ul>

					<p>
						One of the most important considerations for individuals
						looking to hire a virtual assistant is the cost. It’s no
						secret that Filipinos are often cheaper than other
						nationalities, and they’re also easier to train, which means
						less time spent on training equals more savings.
					</p>
				</li>

				{FAQsVAData.map(FAQsVA => (
					<li key={FAQsVA.FAQsVAKey}>
						<h3 className='faqs__va__question'>
							{FAQsVA.FAQsVAQuestion}
						</h3>

						<p className='faqs__va__answer'>{FAQsVA.FAQsVAAnswer}</p>
					</li>
				))}
			</ul>
		</>
	);
};

export default VirtualAssistants;
